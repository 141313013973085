.main-heading {
    font-weight: 900;
    font-size: 80px;
    margin-bottom: 20px;
    height: fit-content;
    line-height: 70px;
    overflow: hidden;
    text-overflow:ellipsis;
}

.sub-heading {
    font-weight: 900;
    font-size: 30px;
}

.paragraph {
    font-weight: 600;
    font-size: 16px;
    white-space: pre-wrap;
}

.link {
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    white-space: pre-wrap;
    text-decoration: none;
    color: rgb(100, 172, 255);
}

@media only screen and (max-width: 649px) {
    .main-heading {
        font-size: 50px;
        line-height: 50px;
    }
    
    .sub-heading {
        font-weight: 900;
        font-size: 18px;
    }
    
    .paragraph {
        font-weight: 600;
        font-size: 12px;
        white-space: pre-wrap;
    }
}