@media only screen and (min-width: 650px) {
    .nav-bar{
        position: relative;
        margin: 94px 0px;
        width: fit-content;
        font-size: 40px;
        font-weight: 900;
    }

    .nav-label{
        line-height: 40px;
        margin: 50px 0px;
        opacity: 50%;
        transition: all ease 0.25s;
        transition-property: color, opacity;
        cursor: pointer;
        user-select: none;
    }
}

@media only screen and (max-width: 649px) {
    .nav-bar{
        position: relative;
        width: fit-content;
        height: 100%;
        font-size: 18px;
        font-weight: 900;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .nav-label{
        line-height: 18px;
        opacity: 50%;
        transition: all ease 0.25s;
        transition-property: color, opacity;
        margin: 30px 10px;
        cursor: pointer;
        height: fit-content;
        width: fit-content;
        writing-mode: vertical-rl;
        text-orientation:sideways;
        user-select: none;
    }
}

.nav-label:hover
{
    opacity: 100%;
    color: rgb(100, 172, 255);
    
}

.nav-label-selected {
    color: rgb(62, 171, 255);
    opacity: 100%;
}