.project-stack {
    position: relative;
    width: 100%;
    height: 100%;
}

.project {
    display: inline-flex;
    flex-direction: column;
    width: 350px;
    min-height: 100px;
    height: fit-content;
    margin: 0px 50px 50px 0px;
}

.project-heading {
    display: flex;
    margin-bottom: 6px;
}

.project-linked-icon {
    width: 24px;
    height: 24px;
}

.tech-stack {
    position: relative;
    height: fit-content;
    width: 100%;
    margin-top: 6px;
}

.tech-bubble{
    display: inline-block;
    position: relative;
    border-radius: 5px;
    border: 1px solid white;
    width: fit-content;
    max-height: 20px;
    padding: 4px 8px;
    margin: 4px 4px 4px 0px;
}
    
.tech-bubble-content {
    color: white;
    font-weight: 700;
    font-size: 16px;
}

@media only screen and (max-width: 649px) {
    .project {
        display: flex;
        flex-direction: column;
        width: 95%;
        max-width: 350px;
        min-height: 100px;
        height: fit-content;
        margin: 0px 0px 30px 0px;
    }

    .tech-bubble-content {
        font-size: 12px;
    }
}