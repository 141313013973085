@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700;800;900&display=swap');

html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../background/bg.webp");
  background-size: cover;
  backdrop-filter: blur(5px);
  box-shadow:inset 0 0 0 20000px rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow: hidden;
  color: white;
  min-height: 500px;
  min-width: 250px;
}

code {
  font-family: 'Raleway', sans-serif;
}

#root {
  position: relative;
  height: 100%;
  width: 100%;
}

@media screen and (max-height:500px) and (min-width:650px){
  body{
    zoom: 0.5;
    background-position-x: -470px;
  }
}

@media only screen and (max-width: 649px) {
  body{
    min-height: 400px;
    background-position-x: -470px;

  }
}