@media only screen and (min-width: 650px) {
    .linked-icon-bar {
        bottom: 0px;
        position: absolute;
        display: flex;
        flex-direction: row;
        width: fit-content;
    }
}

.linked-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.linked-icon > a {
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    line-height: 50px;
}

.linked-icon > a > img { 
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.email-icon {
    margin-top: 3px;
    width: 44px;
    height: 44px;
}

.bar-github-icon {
    position: relative;
    top: 1px;
    width: 48px;
    height: 48px;
}

@media only screen and (max-width: 649px) {
    .linked-icon-bar {
        bottom: 40px;
        left: 35px;
        position: absolute;
        display: flex;
        flex-direction: row;
        width: fit-content;
    }
    .linked-icon {
        width: 35px;
        height: 35px;
        margin-right: 15px;
    }
    
    .linked-icon > a {
        width: fit-content;
        height: fit-content;
        box-sizing: border-box;
        line-height: 35px;
    }
    .email-icon {
        margin-top: 2px;
        width: 31px;
        height: 31px;
    }
    .bar-github-icon {
        position: relative;
        top: 1px;
        width: 34px;
        height: 34px;
    }
}
